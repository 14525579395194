import { createPopper } from '@popperjs/core';

(()=>{
    let mainClass = 'multiselect',
        selects = document.querySelectorAll('.' + mainClass);
    if(!selects) return;

    selects.forEach(select => {
        let elements = {
            input: select.querySelector('.' + mainClass + '__input'),
            optionsWrap: select.querySelector('.' + mainClass + '__options'),
            options: select.querySelectorAll('.' + mainClass + '__option'),
            expand: select.querySelector('.' + mainClass + '__expand'),
        };

        let value = [];

        // Позиционирование @ popperjs
        const sameWidth = {
            name: "sameWidth",
            enabled: true,
            phase: "beforeWrite",
            requires: ["computeStyles"],
            fn: ({ state }) => {
              state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            effect: ({ state }) => {
              state.elements.popper.style.width = `${
                state.elements.reference.offsetWidth
              }px`;
            }
        };

        createPopper(select, elements.optionsWrap, {
            modifiers: [sameWidth]
        });

        // Функции
        let hasValue = () => {
            return elements.value.value ? true : false;
        };

        let toggleList = () => {
            elements.optionsWrap.classList.toggle(mainClass + '__options--visible');
        }

        let showList = () => {
            elements.optionsWrap.classList.add(mainClass + '__options--visible');
        };

        let hideList = () => {
            elements.optionsWrap.classList.remove(mainClass + '__options--visible');
        }
        
        let updateValue = () => {
            console.log(value);
            elements.input.value = value.length ? value.join(', ') : '';
        }
        
        // События
        elements.options.forEach(option => {
            let checkbox = option.querySelector('input');

            checkbox.addEventListener('change', e => {
                if(checkbox.checked) {
                    value.push(option.dataset.value)
                } else {
                    value = value.filter(v => v !== option.dataset.value); 
                }

                updateValue();
            });
        });
        
        elements.input.addEventListener('focus', e => {
            toggleList();
        });

        document.addEventListener('click', e => {
            if(!select.contains(e.target)) hideList();
        });

        elements.expand.addEventListener('click', e => {
            elements.input.focus();
        });
    });
})();