import { CountUp } from 'countUp.js';

// Анимация счётчиков
(()=>{
    let counters = document.querySelectorAll('.index__number');
    if(!counters) return;

    counters.forEach(el => {
        let value = +el.dataset.value;
        
        let counter = new CountUp(el, value, {
            decimalPlaces: value % 1 ? 1 : 0,
            duration: 2,
            separator: ' ',
            enableScrollSpy: true,
        });
        counter.start();
    });
})();