// Открытие меню (mobile)
(()=>{
    let menu = document.querySelector('.mainmenu');
    if(!menu) return false;
    
    document.querySelector('.mainmenu__toggle').addEventListener('click', () => {
        menu.classList.add('mainmenu--visible');
        document.body.style.overflow = 'hidden';
    });

    document.querySelector('.mainmenu__overlay').addEventListener('click', () => {
        menu.classList.remove('mainmenu--visible');
        document.body.style.overflow = 'auto';
    });
})();


// Открытие подменю (mobile)
(()=>{
    let toggles = document.querySelectorAll('.mainmenu__expand');
    if(!toggles) return false;

    toggles.forEach((toggle) => {
        let submenu = toggle.closest('.mainmenu__item').querySelector('.context-menu');
        
        toggle.addEventListener('click', function(e){
            this.classList.toggle('mainmenu__expand--turned');
            submenu.classList.toggle('context-menu--shown');
            e.preventDefault();
            e.stopPropagation();
        });
    });
})();


// Определение текущего пункта меню
(()=>{
    let menu_items = document.querySelectorAll('.mainmenu__item');
    let isCurrentPath = (path) => window.location.pathname == path;
    
    let setCurrentMenuItem = (item) => {
        item.classList.add('mainmenu__link--current');
    }

    for (let i = 0; i < menu_items.length; i++) {
        let menu_link = menu_items[i].querySelector('.mainmenu__link'),
            submenu = menu_items[i].querySelector('.context-menu');
        
        if(isCurrentPath(menu_link.pathname)) {
            setCurrentMenuItem(menu_link);
            break;
        }
        
        if(submenu) {
            submenu.querySelectorAll('.context-menu__item').forEach((el) => {
                if(isCurrentPath(el.pathname)) setCurrentMenuItem(menu_link);
            });
        }
    }
})();


// Открытие панели поиска
(()=>{
    let toggles = document.querySelectorAll('[data-toggle-search]'),
        search = document.querySelector('.search'),
        activeClass = 'search--shown';
    if(!toggles) return false;
    
    toggles.forEach((toggle)=>{
        toggle.addEventListener('click', (e)=>{
            search.classList.toggle(activeClass);

            if(search.classList.contains(activeClass)) {
                let input = search.querySelector('input'),
                    event = new Event('focus');

                setTimeout(() => {
                    input.focus();
                    input.dispatchEvent(event);
                }, 300);
            }
        });
    });
})()