import "./import/modules";
import "./import/components";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import { tns } from 'tiny-slider';
import { Chart, LineController, LineElement, BarController, BarElement, PieController, ArcElement, PointElement, CategoryScale, LinearScale, Filler, Tooltip } from 'chart.js';
import tippy, {followCursor, hideAll as hideTooltips} from 'tippy.js';

let isMobile = window.innerWidth <= 1128;

/* 
 * Увеличение фото (Photoswipe)
 * отдельные фото: data-image-open
 * галлереи: data-gallery-open='[{"src": "", "width": "", "height": ""}]'
 */
(()=>{
    // общие настройки плагина
    let photoSwipeOptions = {
        showHideAnimationType: 'zoom',
        pswpModule: PhotoSwipe,
        bgOpacity: 1,
        mainClass: 'lightbox',
        arrowPrevSVG: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M13 5.93 6.93 12 13 18.07l1.5-1.5L9.93 12l4.57-4.57-1.5-1.5Z"/></svg>',
        arrowNextSVG: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="m10 5.93-1.5 1.5L13.07 12 8.5 16.57l1.5 1.5L16.07 12 10 5.93Z"/></svg>',
        closeSVG: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M4.707 3.293 3.293 4.707 10.586 12l-7.293 7.293 1.414 1.414L12 13.414l7.293 7.293 1.414-1.414L13.414 12l7.293-7.293-1.414-1.414L12 10.586 4.707 3.293Z"/></svg>',
        zoomSVG: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M9 2C5.146 2 2 5.146 2 9s3.146 7 7 7a6.958 6.958 0 0 0 4.574-1.719l.426.426V16l6 6 2-2-6-6h-1.293l-.426-.426A6.958 6.958 0 0 0 16 9c0-3.854-3.146-7-7-7Zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5-5-2.227-5-5 2.227-5 5-5ZM8 6v2H6v2h2v2h2v-2h2V8h-2V6H8Z"/></svg>'
    }

    // отдельные фото
    function initLightboxes() {
        new PhotoSwipeLightbox({
            gallery: '[data-image-open]',
            ...photoSwipeOptions
        }).init();
    }
    initLightboxes();
    
    // галлереи
    function initGalleries() {
        let triggers = document.querySelectorAll('[data-gallery-open]');
        if(!triggers) return false;
    
        triggers.forEach(function(el) {
            el.addEventListener('click', function(e) {
                let gallery = new PhotoSwipe({
                    gallery: el,
                    dataSource: JSON.parse(el.dataset.gallery),
                    ...photoSwipeOptions
                });
                
                gallery.init();
            });
        });
    }
    initGalleries();
})();

// Карта (Google Maps)
(()=>{
    let map, mapElement = document.getElementById("map");

    function initMap() {
        const position = { lat: 41.6465569, lng: 41.6493067 };
    
        const map = new google.maps.Map(mapElement, {
            zoom: 14,
            center: position,
            disableDefaultUI: true,
            mapId: 'f2deed732363bf02',
        });
    
        const pinView = new google.maps.marker.PinView({
            scale: 1.2,
            background: "#FEC50C",
            borderColor: "#CB9E0A",
            glyphColor: "#CB9E0A",
        });
    
        const marker = new google.maps.marker.AdvancedMarkerView({
            map,
            position,
            title: "Batumi Sea Port",
            content: pinView.element,
        });
    }

    if(mapElement) window.initMap = initMap;
})();

// Установка аттрибута data-title для ячеек таблиц
(()=>{
    let tables = document.querySelectorAll('table');
    if(!tables) return;

    tables.forEach(table => {
        let headings = table.querySelectorAll('th');
        if(!headings) return;

        for(let i = 0; i < headings.length; i++) {
            let cells = table.querySelectorAll('td:nth-child('+(i+1)+')');

            cells.forEach(el => {
                el.setAttribute('data-title', headings[i].innerText);
            });
        }
    });
})();

// Открытие страниц по нажатию на элементы с data-href
(()=>{
    let links = document.querySelectorAll('[data-href]');
    if(!links) return;

    links.forEach(link => {
        link.addEventListener('click', e => {
            document.location = link.getAttribute('data-href');
        })
    })
})();

// Раскрытие блоков контента (.box--expandable)
(()=>{
    let boxes = document.querySelectorAll('.box--expandable');
    if(!boxes) return;

    boxes.forEach(box => {
        let header = box.querySelector('.box__header');
        if(!header) return;

        header.addEventListener('click', e => {
            box.classList.toggle('box--expanded');
            setTimeout(() => box.scrollIntoView({block: "start"}), 100);
        });
    });
})();

// Вызов соыбтия "click" при нажатии Enter/Spacebar на элементе (для навигации без мыши)
(()=>{
    document.addEventListener('keyup', function(e) {
        if(["Space", "Enter"].includes(e.code)) {
            e.target.click();
        }
    });
})();

// Слайдер терминалов порта
(()=>{
    let containerClass = '.port-terminals__slider';
    if(!document.querySelector(containerClass)) return false;

    let slider = tns({
        container: containerClass,
        autoWidth: true,
        prevButton: '.port-terminals__controls button:first-child',
        nextButton: '.port-terminals__controls button:last-child',
        nav: false,
        arrowKeys: true,
        mouseDrag: true,
        loop: false,
        rewind: true,
        freezable: false,
    });
})();

// Слайдер истории порта
(()=>{
    if(!document.querySelector('.port-history')) return false;

    let options = {
        loop: false,
        rewind: true,
        axis: window.innerWidth <= 1128 ? 'horizontal' : 'vertical',
        autoHeight: false,
        touch: true,
        speed: 700,
        swipeAngle: 45,
        preventActionWhenRunning: true,
        responsive: {
            1280: {
                autoHeight: true,
            }
        }
    };

    let slider_text = tns({
        container: '.port-history__text-slider',
        navContainer: '.port-history__timeline',
        prevButton: '.port-history__controls button:first-child',
        nextButton: '.port-history__controls button:last-child',
        ...options
    });

    let slider_images = tns({
        container: '.port-history__images',
        controls: false,
        navContainer: '.port-history__timeline',
        ...options
    });

    let setSliderIndex = (slider, index) => {
        if(slider.getInfo().index == index) return;
        slider.goTo(index);
    };

    let setEvents = (slider) => {
        slider.events.on('indexChanged', (info) => {
            setSliderIndex(slider_images, info.index);
            setSliderIndex(slider_text, info.index);
        });
    
        slider.events.on('transitionEnd', (info) => {
            // document.querySelector('.port-history__timeline .tns-nav-active').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
            let target = document.querySelector('.port-history__timeline .tns-nav-active');
            target.parentNode.scroll({left: target.offsetLeft - target.parentNode.offsetLeft, behavior: 'smooth'});
        });
    }

    setEvents(slider_text);
    setEvents(slider_images);
})();

// Слайдер оснащения порта
(()=>{
    let sliders = document.querySelectorAll('.port-equipment__slider'),
        instances = [];
    if(!sliders) return false;

    sliders.forEach(slider => {
        let sliderObj = tns({
            container: slider,
            autoWidth: true,
            nav: false,
            arrowKeys: true,
            mouseDrag: true,
            loop: false,
            freezable: false,
            controls: false,
            autoplay: true,
            // autoplayHoverPause: true,
            rewind: true,
            autoplayButtonOutput: false,
            autoplayResetOnVisibility: false
        });

        instances.push(sliderObj);
    });

    document.addEventListener('tabChange', e => {
        for (let i = 0; i < instances.length; i++) {
            instances[i].destroy();
            instances[i] = instances[i].rebuild();
        }
    });
})();

// Тултипы
(()=>{
    let tooltips = tippy('[data-tippy-content]', {
        animation: 'scale',
        inertia: true,
        delay: [200, 0],
        followCursor: true,
        offset: [0, 16],
        zIndex: 98,
        plugins: [followCursor],
        trigger: isMobile ? 'focus click' : 'mouseenter focus',
        touch: true,
    });
})();

// Charts
(()=>{
    let charts = document.querySelectorAll('.chart');
    if(!charts) return;

    let colors = {
        complementary: '#00B7CB',
        accent: '#FEC50C',
        primary: '#074181',
        border: '#EAF2FA',
        text: '#010D1B',
        background: '#fff',
        gradient: {
            start: 'rgba(0, 183, 203, 0.2)',
            end: 'rgba(0, 183, 203, 0)'
        },
        extra: [
            '#F44336',
            '#8D6E63',
            '#9C27B0',
            '#FF9800',
            '#5C6BC0',
            '#2196F3',
            '#009688'
        ]
    },
    parseData = (el) => {
        try {
            return JSON.parse(el.dataset.value).shift();
        } catch (error) {
            return false;
        }
    },
    generateData = (rows = 5) => {
        let data = {
            label: "отрезок",
            measure: "единиц",
            points: []
        };

        for(let i = 0; i < rows; i++) {
            data.points.push({
                key: i + 1,
                value: Math.floor(Math.random() * 100),
            })
        }
        
        return data;
    }

    Chart.register(LineController, LineElement, BarController, BarElement, PieController, ArcElement, PointElement, CategoryScale, LinearScale, Filler, Tooltip);
    Object.assign(Chart.defaults, {
        backgroundColor: colors.background,
        borderColor: colors.border,
        color: colors.text,
        font: {
            size: 14,
            family: "'Noto Sans', 'Noto Sans Georgian', Helvetica, sans-serif",
        }
    });

    charts.forEach(el => {
        let type = el.dataset.type || 'line',
            data = parseData(el) || generateData(),
            options = {
                aspectRatio: 16 / 10,
                scales: {
                    x: {
                        grid: {
                            drawTicks: false
                        },
                        ticks: {
                            display: false,
                        },
                    },
                    y: {
                        grid: {
                            drawTicks: false
                        },
                        ticks: {
                            display: false,
                        },
                    }
                },
                elements: {
                    point: {
                        radius: 4,
                        hoverRadius: 8,
                        borderWidth: 2,
                        hoverBorderWidth: 4,
                        backgroundColor: colors.complementary,
                        hoverBackgroundColor: colors.accent,
                        borderColor: colors.background,
                        hoverBorderColor: colors.background
                    },
                    line: {
                        borderWidth: 2,
                        tension: 0.2,
                        borderColor: colors.complementary,
                        fill: true,
                        backgroundColor: (context) => {
                            let width, height, gradient;
                            const chart = context.chart;
                            const {ctx, chartArea} = chart;
                            if (!chartArea) return;
        
                            const chartWidth = chartArea.right - chartArea.left;
                            const chartHeight = chartArea.bottom - chartArea.top;
        
                            if (!gradient || width !== chartWidth || height !== chartHeight) {
                                width = chartWidth;
                                height = chartHeight;
                                gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                                gradient.addColorStop(0, colors.gradient.start);
                                gradient.addColorStop(1, colors.gradient.end);
                            }
        
                            return gradient;
                        }
                    },
                    bar: {
                        backgroundColor: colors.extra
                    },
                    arc: {
                        backgroundColor: colors.extra
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        backgroundColor: colors.primary,
                        padding: {
                            x: 12,
                            y: 8
                        },
                        caretPadding: 12,
                        caretSize: 4,
                        cornerRadius: 2,
                        displayColors: false,
                        titleSpacing: 0,
                        bodySpacing: 0,
                        titleFont: {
                            size: 12,
                            weight: 'normal'
                        },
                        bodyFont: {
                            size: 14,
                            weight: 'bold'
                        },
                        titleAlign: 'center',
                        bodyAlign: 'center',
                        callbacks: {
                            title: (context) => {
                                return context[0].label + ' ' + data.label;
                            },
                            label: (context) => {
                                return context.formattedValue + ' ' + data.measure;
                            }
                        },
                    },
                },
                interaction: type == 'line' ? {
                    mode: 'index',
                    intersect: false
                } : {
                    mode: 'nearest',
                    intersect: true
                },
            };
        
        new Chart(el, {
            type,
            options,
            data: {
                labels: data.points.map(row => row.key),
                datasets: [{
                    data: data.points.map(row => row.value),
                }]
            }                
        })
    });
})();

// Date Range Picker
(()=>{
    let pickers = document.querySelectorAll('.daterangepicker');
    if(!pickers) return;

    let updateRange = (picker) => {
        let inputs = picker.querySelectorAll('.daterangepicker__input');
        picker.querySelector('.daterangepicker__value').value = new Date(inputs[0].value).toLocaleDateString() + ' – ' + new Date(inputs[1].value).toLocaleDateString();
    }

    pickers.forEach(picker => {         
        let inputs = picker.querySelectorAll('.daterangepicker__input');

        picker.addEventListener('click', e => {
            if(!picker.classList.contains('shown')) {
                picker.classList.add('shown');

                setTimeout(() => {
                    inputs[0].focus();
                    inputs[0].showPicker();
                }, 200);
            }
        });

        document.addEventListener('click', function(event) {
            if(!picker.contains(event.target)) picker.classList.remove('shown');
        });

        for (let i = 0; i < inputs.length; i++) {
            inputs[i].max = new Date().toISOString().split("T")[0];
            
            inputs[i].addEventListener('input', e => {
                let next = inputs[i+1];

                if(next) {
                    next.min = inputs[i].value;
                    next.focus();
                    next.showPicker();
                } else {
                    updateRange(picker);
                    picker.classList.remove('shown');
                }
            })
        }
    })
})();

// Tabs
(()=>{
    let tabGroups = document.querySelectorAll('[data-tabgroup]'),
        activeClass = 'tabs__item--active',
        tabEvent = new CustomEvent("tabChange");

    if(tabGroups) {
        tabGroups.forEach(tabGroup => {
            let toggles = tabGroup.querySelectorAll('[data-tab-toggle]'),
                tabs = tabGroup.querySelectorAll('[data-tab]'),
                currentTab = null;

            tabs.forEach(el => el.style.display = 'none');
            
            toggles.forEach(toggle => {
                let id = toggle.dataset.tabToggle,
                    targetTab = tabGroup.querySelector('[data-tab="'+id+'"]');

                if(!targetTab) return;

                currentTab = toggle.classList.contains(activeClass) ? targetTab : currentTab;
                
                toggle.addEventListener('click', e => {
                    tabs.forEach(el => el.style.display = 'none');
                    targetTab.style.display = '';
                    toggles.forEach(el => el.classList.remove(activeClass));
                    toggle.classList.add(activeClass);
                    document.dispatchEvent(tabEvent);
                });
            });

            if(currentTab) currentTab.style.display = '';
        });
    }
})();