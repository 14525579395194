import Panzoom from '@panzoom/panzoom';
import tippy, {hideAll as hideTooltips} from 'tippy.js';

let maps = document.querySelectorAll('.route-map');

if(maps) {
	function isInViewport(el) {
		const rect = el.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	
		);
	}

	maps.forEach(container => {
		let map = container.querySelector('.route-map__svg'),
				  isMobile = window.innerWidth <= 1128;
		if(!map) return;
	
		// Pan
		let pan = Panzoom(map, {
			animate: true,
			cursor: 'default',
			startScale: isMobile ? 4 : 2,
			startY: isMobile ? -75 : -75,
			startX: isMobile ? -25 : -50,
			minScale: isMobile ? 2 : 1,
			maxScale: isMobile ? 4 : 2,
			step: 1,
			pinchAndPan: true,
			disablePan: isMobile ? false : true,
		});

		// Zoom
		map.addEventListener('wheel', function(event) {
			if (!event.shiftKey) return;
			pan.zoomWithWheel(event);
		});

		let autoZoom = () => {
			if (isInViewport(container)) {
				if(!isMobile) {
					pan.pan(0, 0, {
						force: true
					});
				} else {
					pan.pan(-25, -50, {
						force: true
					});					
				}

				pan.zoom(pan.getOptions().minScale, {
					duration: 1000
				});
			}
		}

		document.addEventListener('scroll', autoZoom);
		map.addEventListener('panzoomend', autoZoom);

		map.addEventListener('panzoomstart', e => {
			hideTooltips();
		});
	});
}