// Смена позиции если меню не влезает в ширину экрана
(()=>{
    let menus = document.querySelectorAll('.context-menu');
    if(!menus) return false;

    menus.forEach(el => {
        if(el.getBoundingClientRect().left + el.offsetWidth > window.innerWidth) el.classList.add('context-menu--right')
    });
})();

// Открытие меню по нажатию
(()=>{
    let menus = document.querySelectorAll('.context-menu:not(.context-menu--hoverable)');
    if(!menus) return false;

    let toggleMenu = (menu) =>  menu.classList.toggle('context-menu--shown');
    let closeMenu = (menu) => menu.classList.remove('context-menu--shown');

    menus.forEach(menu => {
        let toggle = menu.parentNode.querySelector('[data-cm-toggle]');
        let wrap = menu.closest('.context-menu__wrap');

        // открытие/закрытие по нажатию на переключатель
        toggle.addEventListener('click', (event) => {
            if (event.isTrusted) toggleMenu(menu);
            event.stopPropagation();
        });

        // закрытие по нажатию вне меню
        document.addEventListener('click', function(event) {
            if(!wrap.contains(event.target)) closeMenu(menu);
        });

        // закрытие при потере фокуса
        document.addEventListener('focusin', function() {
            if(!wrap.contains(document.activeElement)) closeMenu(menu);
        }, true);
    });
})();