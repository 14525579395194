import { createPopper } from '@popperjs/core';

(()=>{
    let mainClass = 'select-search',
        selects = document.querySelectorAll('.' + mainClass);
    if(!selects) return;

    selects.forEach(select => {
        let elements = {
            input: select.querySelector('.' + mainClass + '__input'),
            value: select.querySelector('.' + mainClass + '__value'),
            optionsWrap: select.querySelector('.' + mainClass + '__options'),
            options: select.querySelectorAll('.' + mainClass + '__option'),
            clear: select.querySelector('.' + mainClass + '__clear'),
            expand: select.querySelector('.' + mainClass + '__expand'),
        };

        let current = {
            key: undefined,
            value: undefined
        }

        // Позиционирование @ popperjs
        const sameWidth = {
            name: "sameWidth",
            enabled: true,
            phase: "beforeWrite",
            requires: ["computeStyles"],
            fn: ({ state }) => {
              state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            effect: ({ state }) => {
              state.elements.popper.style.width = `${
                state.elements.reference.offsetWidth
              }px`;
            }
        };

        createPopper(select, elements.optionsWrap, {
            modifiers: [sameWidth]
        });

        // Функции
        let hasValue = () => {
            return elements.value.value ? true : false;
        };

        let toggleControls = () => {
            elements.clear.style.display = hasValue() ? '' : 'none';
            elements.expand.style.display = hasValue() ? 'none' : '';
        }

        let showList = () => {
            elements.optionsWrap.classList.add(mainClass + '__options--visible');
        };

        let hideList = () => {
            elements.optionsWrap.classList.remove(mainClass + '__options--visible');
        }

        let filterOptions = () => {
            let query = elements.input.value.trim();
            
            for (let i = 0; i < elements.options.length; i++) {
                let suitable = elements.options[i].innerText.trim().toLowerCase().includes(query.toLowerCase());
                elements.options[i].innerHTML = elements.options[i].innerHTML.replace(/(<([^>]+)>)/ig, "");

                if(suitable) {
                    elements.options[i].style.display = '';

                    if(query) {
                        let regex = new RegExp(query, 'gi');
                        elements.options[i].classList.add(mainClass + '__option--suitable');
                        elements.options[i].innerHTML = elements.options[i].innerText.replace(regex,'<b>$&</b>');
                    }
                } else {
                    elements.options[i].style.display = 'none';
                    elements.options[i].classList.remove(mainClass + '__option--suitable');
                }
            }
        }
        
        let setValue = (key, value = '') => {
            elements.input.value = key;
            elements.value.value = value ? value : key;
            toggleControls();
        };

        let clearValue = () => {
            setValue('');
        };
        
        // События
        elements.input.addEventListener('focus', e => {
            current.key = elements.input.value;
            current.value = elements.value.value;

            clearValue();
            filterOptions();
            showList();
        });

        elements.input.addEventListener('blur', e => {
            if(current.value) setValue(current.key, current.value);
            if(!elements.value.value) clearValue();

            hideList();
        });

        elements.input.addEventListener('input', e => {
            filterOptions();
        });

        elements.options.forEach(option => {
            option.addEventListener('click', e => {
                setValue(option.textContent, option.dataset.value);
                hideList();
                e.preventDefault();
            });
        });

        elements.clear.addEventListener('click', e => {
            clearValue();
        });

        elements.expand.addEventListener('click', e => {
            elements.input.focus();
        });

        document.addEventListener('DOMContentLoaded', e => {
            toggleControls();
        });
    });
})();