import MicroModal from 'micromodal';

MicroModal.init({
    openTrigger: 'data-modal-open',
    closeTrigger: 'data-modal-close',
    openClass: 'modal--shown',
    disableScroll: true,

    onShow: (modal, element, event) => {
        let video_wrap = modal.querySelector('.modal__video');
        if(video_wrap) video_wrap.querySelector('video').play();
        event.preventDefault();
        event.stopPropagation();
    },

    onClose: modal => {
        let video_wrap = modal.querySelector('.modal__video');
        if(video_wrap) video_wrap.querySelector('video').pause();
    },
});