import Panzoom from '@panzoom/panzoom';
import tippy, {hideAll as hideTooltips} from 'tippy.js';

let schemes = document.querySelectorAll('.scheme--interactive');

if(schemes) {
	schemes.forEach(container => {
		let scheme = container.querySelector('.scheme__svg');
		if(!scheme) return;
	
		let	scheme_wrap = container.querySelector('.scheme__pan'),
			scaleBarEl = container.querySelector('.scheme__scale'),
			zoom_buttons = container.querySelectorAll('.scheme__zoom-button'),
			isMobile = window.innerWidth <= 1128;
	
		// Pan
		let pan = Panzoom(scheme, {
			animate: true,
			pinchAndPan: true,
			cursor: 'grab',
			minScale: 1,
			maxScale: isMobile ? 4 : 2
		});
	
		// Zoom
		scheme.addEventListener('wheel', function(event) {
			if (!event.shiftKey) return;
			pan.zoomWithWheel(event);
		});
	
		if(zoom_buttons) {
			let changeZoom = (shift) => {
				let cur = +scaleBarEl.value,
					to = cur + shift;

				scaleBarEl.value = to;
				scaleBarEl.dispatchEvent(new Event('input', {bubbles:true}));
			}
			
			zoom_buttons.forEach(el => {
				el.addEventListener('click', function(event){
					switch(el.dataset.zoom) {
						case 'in':
							changeZoom(20);
							break;
						case 'out':
							changeZoom(-20);
							break;
					}
				});
			});
		}
	
		if(scaleBarEl) {
			let updateScaleBar = () => {
				let params = {
						max: pan.getOptions().maxScale,
						min: pan.getOptions().minScale,
						cur: pan.getScale(),
					},
					pos = (params.cur - params.min) / (params.max - params.min) * 100;
	
				scaleBarEl.value = pos;
			}

			let updateMapScale = (percent) => {
				let params = {
					max: pan.getOptions().maxScale,
					min: pan.getOptions().minScale,
					cur: pan.getScale(),
				},
				scale = ((params.max - params.min) * percent * 0.01) + params.min;
				pan.zoom(scale);
			}
	
			scheme.addEventListener('panzoomzoom', (event) => {
				updateScaleBar();
			});

			scaleBarEl.addEventListener('input', (event) => {
				updateMapScale(event.target.value);
			});
		}

		scheme.addEventListener('panzoomstart', e => {
			hideTooltips();
		});
		
		// Popovers
		let ships = container.querySelectorAll('.ship');
		if(ships) {		  
			ships.forEach(ship => {
				tippy(ship, {
					trigger: isMobile ? 'focus click' : 'mouseenter focus',
					arrow: true,
					animation: 'scale',
					delay: [200, 0],
					offset: [0, 16],
					zIndex: 98,
					theme: 'light',
					touch: true,
					content(reference) {
						const template = document.getElementById('vessel-popover');
						return template ? template.innerHTML : 'Судно';
					},
					allowHTML: true
				});
			});
		}
	});
}